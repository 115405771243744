
import React, {Fragment} from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { format } from 'date-fns'
import Layout from "../components/layout"
import htmlDecode from "html-entities-decoder";
import Breadcrumb from "../components/Global/Breadcrumb"

import { el } from 'date-fns/locale'

import User from "./../images/user.svg"
import Calendar from "./../images/calendar.svg"

import "./../css/BlogTemplate.css"




function GeneralBlogTemplate(props) {
    let data = props.data.wpgraphql;

    console.log(props)

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://karanikolascon.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://karanikolascon.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.page.breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
               
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                projects={props.pageContext.projectsCategories}
                preHeader={data.template.contactInformationTemplate}
            >
                <div>
                    {data.page.breadcrumbAcf.breadcrumbName && (
                        <section>
                            <Breadcrumb list={getBreadcrumb()} image={data.page.breadcrumbAcf.image} h1={data.page.breadcrumbAcf.breadcrumbName} />
                        </section>
                    )}

                    <section>
                        <div className="container w-full m-auto my-12 md:my-20">
                            {data.categories.nodes.map((category, index) => (
                                <div className="w-full flex flex-wrap pt-8">
                                    { category.posts.nodes.length > 0 && (
                                        <Fragment>
                                            <h2 className="pb-4">{category.name}</h2>
                                            <div className="w-full flex flex-wrap">
                                                {category.posts.nodes.map((post, i) => {
                                                    return (
                                                        <article className="w-full md:w-1/2 lg:w-1/3 p-4" key={`post-tab-${i}`}>
                                                            <Link to={`/blog/${category.slug}/${post.slug}/`}>
                                                                <div className="relative bp-article">
                                                                    <div className="blog-f-img" style={{ padding: "8px" }}>
                                                                        {post.featuredImage && (
                                                                            <GatsbyImage
                                                                                image={post.featuredImage.node.imageFile.childImageSharp.gatsbyImageData}
                                                                                alt={post.featuredImage.node.altText}
                                                                                className="h-full w-full shadow bp-img-wrapper"
                                                                                loading="lazy" />
                                                                        )}
                                                                    </div>
                                                                    <div className="w-full z-10">
                                                                        <div className="flex px-2 md:px-6 blog-info pt-2">
                                                                            <div className="flex justify-center">
                                                                                <div>
                                                                                    <img src={User} style={{ width: "15px", marginRight: "5px" }} />
                                                                                </div>
                                                                                <div>
                                                                                    {post.author.node.firstName}
                                                                                </div>
                                                                            </div>
                                                                            <div className="px-2">-</div>
                                                                            <div className="flex justify-center">
                                                                                <div>
                                                                                    <img src={Calendar} style={{ width: "15px", marginRight: "5px" }} />
                                                                                </div>
                                                                                <div>{format(new Date(post.date), 'dd MMMM, yyyy', { locale: el })}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" px-2 md:px-6 pt-2 bp-info">
                                                                        <h2>{htmlDecode(post.title)}</h2>
                                                                        <div className="bp-excerpt mt-4" dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </article>

                                                    );
                                                })}
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            ))}

                        </div>
                    </section>
                </div>
            </Layout>
        </div>
    )
}

export default GeneralBlogTemplate;

export const pageQuery = graphql`query GET_GENERAL_BLOG_PAGE($id: ID!, $themeTemplateUri: ID!) {
  wpgraphql {
    page(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      title
      content
      breadcrumbAcf {
        breadcrumbName
        image {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    template(id: $themeTemplateUri, idType: SLUG) {
      contactInformationTemplate {
        telephoneNumber
        mobileNumber
        supportEmail
        hoursLabel
        hoursTitle
        form {
          title
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }

    categories(first: 100, where: {language: EL}) {
        nodes {
          id
          databaseId
          slug
          name
          posts(first: 3) {
                nodes {
                title
                excerpt
                slug
                date
                author {
                  node {
                    firstName
                    lastName
                    avatar {
                      url
                    }
                  }
                }
                featuredImage {
                  node {
                    sourceUrl
                    altText
                    imageFile {
                        childImageSharp {
                          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                        }
                    }
                  }
                }
              }
            }
        }
      }

    
  }
}
`
